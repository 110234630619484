require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("channels")

const images = require.context('../img', true)
const imagePath = (name) => images(name, true)

import '../stylesheets/application'
import '../stylesheets/custom'
import './bootstrap_custom.js'
import '@fortawesome/fontawesome-free/js/all.js'
import toastr from 'toastr'
window.toastr = toastr
import Highcharts from 'highcharts'
import addMore from "highcharts/highcharts-more"
addMore(Highcharts)
window.Highcharts = Highcharts

toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": true,
  "progressBar": true,
  "positionClass": "toast-top-center",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "200",
  "hideDuration": "600",
  "timeOut": "3000",
  "extendedTimeOut": "600",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}
